import React, { useState } from "react"
import { Link } from "gatsby"

import HoverText from "./HoverText"

import hamburger from "/src/images/hamburger.svg"
import hamburgerClose from "/src/images/hamburger-close.svg"
import { GlobalDataContext } from "../layouts"

export default ({ data }) => {
  const globalData = React.useContext(GlobalDataContext)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleMenuDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const MenuOptions = ({ classes }) => (
    <>
      <HoverText textColour="text-mvp-light-blue" classes={classes}>
        <Link to="/portfolio" onClick={() => setIsDrawerOpen(false)}>
          {data.portfolio}
        </Link>
      </HoverText>

      <HoverText textColour="text-mvp-light-blue" classes={classes}>
        <Link to="/team/investment" onClick={() => setIsDrawerOpen(false)}>
          {data.team}
        </Link>
      </HoverText>

      <HoverText textColour="text-mvp-light-blue" classes={classes}>
        <Link to="/code-of-conduct" onClick={() => setIsDrawerOpen(false)}>
          {data.conduct}
        </Link>
      </HoverText>

      {globalData && globalData.isPodcastEnabled && (
        <HoverText textColour="text-mvp-light-blue" classes={classes}>
          <Link
            to="/the-product-market-fit-show"
            onClick={() => setIsDrawerOpen(false)}
          >
            {data.podcasts}
          </Link>
        </HoverText>
      )}
    </>
  )

  const HorizontalMenu = () => (
    <div
      className="fixed top-0 left-0 flex w-full bg-mvp-dark-blue h-80px sm:h-100px transform
        sm:absolute"
      style={{
        transition: "transform 0.3s ease",
      }}
    >
      <div
        className="container flex justify-between items-end py-6 text-mvp-light-blue
        sm:py-8 sm:justify-start"
      >
        <Link to="/">
          <img
            src={data.logo.url}
            alt="Mistal VP"
            className="w-150px sm:mr-12"
          />
        </Link>

        <img
          src={hamburger}
          className="cursor-pointer sm:hidden"
          onClick={toggleMenuDrawer}
        />

        <div
          className="hidden space-x-4
          sm:block"
        >
          <MenuOptions />
        </div>
      </div>
    </div>
  )

  const MenuDrawer = () => (
    <div
      className={`fixed top-0 h-full w-full bg-mvp-dark-blue transform ease-in-out transition-all duration-300
    ${isDrawerOpen ? "translate-x-0" : "-translate-x-full"}
    `}
    >
      <div className="container border-b-2">
        <div className="flex justify-between py-6">
          <Link to="/">
            <img
              src={data.logo.url}
              alt="Mistal VP"
              className="w-150px -mt-10px"
            />
          </Link>

          <img
            src={hamburgerClose}
            className="cursor-pointer sm:hidden"
            onClick={toggleMenuDrawer}
          />
        </div>

        <div className="border-b-2 border-mvp-light-blue" />

        <div className="flex flex-col">
          <MenuOptions classes="pt-6 text-2xl font-light" />
        </div>
      </div>
    </div>
  )

  return (
    <div className="relative z-50">
      <HorizontalMenu />
      <MenuDrawer />
    </div>
  )
}
