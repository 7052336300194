import React, { useRef, useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

import HoverText from "./HoverText"

export const INPUT_COLOUR = {
  DARK_BLUE: "DARK_BLUE",
  LIGHT_BLUE: "LIGHT_BLUE",
}

const InputBox = ({
  colour,
  buttonLabel,
  placeholderLabel,
  className,
  buttonClasses,
  signupSuccessMessage,
  signupSuccessMessageClasses,
  mailchimpList,
  onSubmit,
}) => {
  let inputClasses
  let inputButtonClasses

  const inputRef = useRef()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const inputClassesDarkBlue =
    "bg-mvp-dark-blue-12 text-mvp-dark-blue-40 placeholder-mvp-dark-blue-40 focus:ring-mvp-dark-blue"
  const inputClassesLightBlue =
    "bg-mvp-lighter-blue text-mvp-light-blue placeholder-mvp-light-blue"

  const buttonClassesDarkBlue = "bg-mvp-dark-blue text-mvp-light-blue"
  const buttonClassesLightBlue = "bg-mvp-light-blue text-mvp-dark-blue"

  switch (colour) {
    case INPUT_COLOUR.DARK_BLUE:
      inputClasses = inputClassesDarkBlue
      inputButtonClasses = buttonClassesDarkBlue
      break
    case INPUT_COLOUR.LIGHT_BLUE:
      inputClasses = inputClassesLightBlue
      inputButtonClasses = buttonClassesLightBlue
      break
    default:
      inputClasses = inputClassesDarkBlue
      inputButtonClasses = buttonClassesDarkBlue
  }

  const isEmailValid = (email) => {
    const regex = /^\S+@\S+\.\S+$/
    return regex.test(String(email).toLowerCase())
  }

  const subscribeToNewsletter = (email) => {
    setIsSubmitted(true)

    addToMailchimp(email, {}, mailchimpList ? mailchimpList : undefined)
      .then((response) => {
        setSubmitError(false)
        setSubmitSuccess(true)
      })
      .catch((error) => {
        setSubmitError(true)
        console.error(error)
      })
  }

  const handleSubmit = () => {
    const emailAddress = inputRef.current.value

    setSubmitError(false)

    if (isEmailValid(emailAddress)) {
      subscribeToNewsletter(emailAddress)
      setSubmitSuccess(signupSuccessMessage)
      onSubmit()
    } else {
      setSubmitError(true)
    }
  }

  const handleEnterKeyDown = (event) => {
    const ENTER_KEY_CODE = 13

    setSubmitError(false)

    if (event.keyCode === ENTER_KEY_CODE) {
      handleSubmit()
    }
  }

  return (
    <div className={`flex flex-col xl:flex-row ${className}`}>
      {isSubmitted && !!submitSuccess && (
        <div className={`font-light text-24px ${signupSuccessMessageClasses}`}>
          {signupSuccessMessage}
        </div>
      )}
      {!isSubmitted && !submitSuccess && (
        <>
          <input
            ref={inputRef}
            type="text"
            placeholder={placeholderLabel}
            onKeyDown={handleEnterKeyDown}
            className={`rounded-full py-2 px-4 mb-4 outline-none text-xl font-light focus:ring
         xl:flex-grow xl:mb-0 xl:flex-grow-2 ${inputClasses} 
            ${submitError && "ring ring-pink-600"}`}
          />

          <HoverText>
            <input
              type="button"
              value={buttonLabel}
              onClick={handleSubmit}
              className={`max-w-175px rounded-full py-3 cursor-pointer text-sm tracking-widest uppercase
          xl:max-w-none xl:px-14 xl:transform xl:-translate-x-12 
          ${inputButtonClasses} ${buttonClasses}`}
            />
          </HoverText>
        </>
      )}
    </div>
  )
}

export default InputBox
