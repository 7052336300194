import React from "react"
import { Link } from "gatsby"
import InputBox, { INPUT_COLOUR } from "../InputBox"
import { GlobalDataContext } from "../../layouts"

const LightBlueFooter = ({ data }) => {
  const globalData = React.useContext(GlobalDataContext)

  return (
    <div className="bg-mvp-light-blue">
      <div className="container py-10 md:py-24">
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div className="mb-16 md:mb-0q">
            <h1
              className="font-light text-36px mb-4
              md:text-4xl xl:text-5xl"
            >
              {data.newsletterTitle}
            </h1>

            <div className="md:pr-8 xl:pr-0">
              <InputBox
                color={INPUT_COLOUR.DARK_BLUE}
                buttonLabel={data.newsletterButton}
                placeholderLabel={data.newsletterInputPlaceholder}
                signupSuccessMessage={globalData.newsletterSignupSuccess}
              />
            </div>
          </div>

          <div
            className="grid grid-cols-2 font-light
          lg:grid-cols-11
          xl:grid-cols-3 xl:gap-x-6
          2xl:grid-cols-12"
          >
            <div
              className="hidden 
          lg:block lg:col-span-1
          xl:hidden
          2xl:block 2xl:col-span-3"
            ></div>

            {/* Column one */}
            <div
              className="
          lg:col-span-5
          xl:col-span-1
          2xl:col-span-3"
            >
              <ul className="font-semibold">{data.aboutTitle}</ul>
              <ul>
                <Link to="/code-of-conduct">{data.aboutCodeOfConduct}</Link>
              </ul>
              {globalData && globalData.isPodcastEnabled && (
                <ul>
                  <Link to="/the-product-market-fit-show">
                    {data.aboutThePmfShow}
                  </Link>
                </ul>
              )}
            </div>

            {/* Column two */}
            <div
              className="
          lg:col-span-5
          xl:col-span-1
          2xl:col-span-3"
            >
              <ul className="font-semibold">{data.portfolioTitle}</ul>
              <ul>
                <Link to="/portfolio/?b2b">{data.portfolioSaas}</Link>
              </ul>
              <ul>
                <Link to="/portfolio/?enterprise">
                  {data.portfolioEnterprise}
                </Link>
              </ul>
              <ul>
                <Link to="/portfolio/?marketplaces">
                  {data.portfolioMarketplace}
                </Link>
              </ul>
            </div>

            <div
              className="hidden 
          lg:block lg:col-span-1
          xl:hidden"
            ></div>

            {/* Column three */}
            <div
              className="
          lg:col-span-5
          xl:col-span-1
          2xl:col-span-3"
            >
              <ul className="font-semibold">{data.teamTitle}</ul>
              <ul>
                <Link to="/team/investment">{data.teamInvestment}</Link>
              </ul>
              <ul>
                <Link to="/team/fellows">{data.teamFellows}</Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LightBlueFooter
