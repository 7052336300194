import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ favicon, defaultTitle, metaTags }) => {
  return (
    <Helmet>
      <title>{metaTags?.title || defaultTitle}</title>

      <meta
        property="og:title"
        key="og:title"
        content={metaTags?.title || defaultTitle}
      />

      <meta
        name="description"
        content={metaTags?.description}
        key="description"
      />
      <meta
        property="og:description"
        content={metaTags?.description}
        key="og:description"
      />

      <link rel="icon" href={favicon} />
    </Helmet>
  )
}

export default Seo
