import React from "react"

import chrome from "/src/images/browsers/chrome.png"
import firefox from "/src/images/browsers/firefox.png"
import safari from "/src/images/browsers/safari.png"
import edge from "/src/images/browsers/edge.png"

const UnsupportedBrowser = ({ data }) => {
  return (
    <>
      <div className="h-80px sm:h-100px" style={{ backgroundColor: "#03045E" }}>
        <div
          className="container py-6
        sm:py-8"
        >
          <img
            src={data.logo.url}
            alt="Mistal Venture Partners logo"
            className="w-96px"
          />
        </div>
      </div>

      <div className="container py-6">
        <div class="text-4xl">Improve your experience</div>

        <div class="max-w-screen-sm py-6">
          You’re using an unsupported web browser. We recommend you use an
          up-to-date version of one of these browsers:
        </div>

        <ul className="text-center">
          <li className="float-left m-10 ml-0">
            <a href="https://google.com/chrome">
              <img alt="Chrome logo" width="80" src={chrome} />
              <p className="pt-2">Chrome</p>
            </a>
          </li>
          <li className="float-left m-10">
            <a href="https://mozilla.org/firefox/new">
              <img alt="Firefox logo" width="80" src={firefox} />
              <p className="pt-2">Firefox</p>
            </a>
          </li>
          <li className="float-left m-10">
            <a href="https://support.apple.com/downloads/safari">
              <img alt="Safari logo" width="80" src={safari} />
              <p className="pt-2">Safari</p>
            </a>
          </li>
          <li className="float-left m-10 mr-0">
            <a href="https://microsoft.com/edge">
              <img alt="Edge logo" width="80" src={edge} />
              <p className="pt-2">Edge</p>
            </a>
          </li>
        </ul>

        <div className="clear-both py-8">
          <a href="?allow-unsupported-browser">Continue anyway ➔</a>
        </div>
      </div>
    </>
  )
}

export default UnsupportedBrowser
