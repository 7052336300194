import React, { useEffect, useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Footer from "../components/Footer"
import Header from "../components/Header"
import "/src/css/index.css"
import { isBrowserInternetExplorer } from "../utils/browser"
import UnsupportedBrowser from "../components/UnsupportedBrowser"
import Seo from "../components/Seo"

const GlobalDataContext = React.createContext({})
const MetaTagsContext = React.createContext({
  metaTags: "",
  setMetaTags: () => "",
})

const StickyHeaderPaddingWrapper = styled.div`
  padding-top: 80px;

  @media (min-width: 640px) {
    padding-top: 100px;
  }
`

const Layout = ({ children }) => {
  const [metaTags, setMetaTags] = useState({})
  const [isInternetExplorer, setIsInternetExplorer] = useState(false)

  const metaTagsValue = useMemo(() => ({ metaTags, setMetaTags }), [metaTags])

  const data = useStaticQuery(graphql`
    {
      strapiHeaderMenu {
        portfolio
        team
        conduct
        podcasts
        logo {
          url
        }
      }
      strapiFooter {
        newsletterTitle
        newsletterInputPlaceholder
        newsletterButton
        aboutTitle
        aboutCodeOfConduct
        aboutThePmfShow
        teamTitle
        teamInvestment
        teamFellows
        portfolioTitle
        portfolioSaas
        portfolioEnterprise
        portfolioIot
        portfolioMarketplace
        copyrightLine1
        termsOfUse
        backToTop
        copyrightLine2
      }
      strapiGlobalApp {
        podcastSignupTitle
        podcastSignupInputPlaceholder
        podcastSignupButton
        podcastEpisodePreviousClip
        podcastEpisodeNextClip
        newsletterSignupSuccess
        podcastSignupSuccess
        isPodcastEnabled
        podcastTranscriptLabel
      }
      strapiMetaData {
        defaultPageTitle
        titleCodeOfConduct
        titleHome
        titlePodcast
        titlePodcastEpisode
        titlePortfolio
        titleTeamFellows
        titleTeamInvestment
        titleTermsOfUse
        favicon {
          url
        }
      }
    }
  `)

  useEffect(() => {
    const queryParam = window.location.search.substring(
      1,
      window.location.search.length
    )

    if (queryParam !== "allow-unsupported-browser") {
      setIsInternetExplorer(isBrowserInternetExplorer())
    }

    console.info(`App dev by Graham Thomas - grahamt84@gmail.com`)
  }, [])

  return (
    <>
      <GlobalDataContext.Provider value={data.strapiGlobalApp}>
        <MetaTagsContext.Provider value={metaTagsValue}>
          <Seo
            metaTags={metaTags}
            favicon={data.strapiMetaData.favicon.url}
            defaultTitle={data.strapiMetaData.defaultPageTitle}
          />

          {isInternetExplorer && (
            <UnsupportedBrowser data={data.strapiHeaderMenu} />
          )}
          {!isInternetExplorer && (
            <div>
              <Header data={data.strapiHeaderMenu} />
              <StickyHeaderPaddingWrapper>
                {children}
              </StickyHeaderPaddingWrapper>
              <Footer data={data.strapiFooter} />
            </div>
          )}
        </MetaTagsContext.Provider>
      </GlobalDataContext.Provider>
    </>
  )
}

export default Layout

export { GlobalDataContext, MetaTagsContext }
