import React from "react"

const HoverText = ({ children, textColour, hoverColour, classes }) =>
  React.cloneElement(children, {
    className: `${
      children.props.className
    } transition transition-colors duration-300 ease
      ${textColour ? textColour : "text-mvp-dark-blue"}
      ${hoverColour ? hoverColour : "hover:text-white"}
      ${classes}
    `,
  })

export default HoverText
