import React from "react"

import DarkBlueFooter from "./DarkBlue"
import LightBlueFooter from "./LightBlue"

const Footer = ({ data }) => {
  const scrollToTop = () => {
    const rootElement = document.documentElement
    rootElement.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      <LightBlueFooter data={data} />
      <DarkBlueFooter scrollToTop={scrollToTop} data={data} />
    </>
  )
}

export default Footer
