import React from "react"
import { Link } from "gatsby"

import backToTop from "/src/images/back-to-top.svg"

import HoverText from "../HoverText"

const DarkBlueFooter = ({ scrollToTop, data }) => (
  <div className="bg-mvp-dark-blue text-mvp-light-blue">
    <div className="container flex flex-col py-6 md:flex-row md:py-4 md:justify-between">
      <div className="flex items-center">
        {/* Desktop */}
        <div className="hidden md:flex md:text-sm">
          &copy;&nbsp;{data.copyrightLine1}&nbsp;
          {data.copyrightLine2}&nbsp;
          <Link to="/terms-of-use" className="underline">
            {data.termsOfUse}
          </Link>
        </div>
      </div>

      {/* Mobile */}
      <div className="text-center md:hidden">
        <div className="text-14px tracking-wide">
          &copy; {data.copyrightLine1}
          <br />
          {data.copyrightLine2}&nbsp;
          <Link to="/terms-of-use" className="underline">
            {data.termsOfUse}
          </Link>
        </div>
      </div>

      <div
        className="hidden md:flex items-center cursor-pointer"
        onClick={scrollToTop}
      >
        <HoverText>
          <span className="uppercase text-xxs tracking-widest text-mvp-light-blue">
            {data.backToTop}
          </span>
        </HoverText>
        <img src={backToTop} alt="Navigate to top of page" className="ml-2" />
      </div>
    </div>
  </div>
)

export default DarkBlueFooter
