// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-strapi-company-profiles-url-js": () => import("./../../../src/pages/portfolio/{StrapiCompanyProfiles.url}.js" /* webpackChunkName: "component---src-pages-portfolio-strapi-company-profiles-url-js" */),
  "component---src-pages-team-fellows-js": () => import("./../../../src/pages/team/fellows.js" /* webpackChunkName: "component---src-pages-team-fellows-js" */),
  "component---src-pages-team-investment-js": () => import("./../../../src/pages/team/investment.js" /* webpackChunkName: "component---src-pages-team-investment-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-the-product-market-fit-show-episodes-strapi-podcast-episodes-url-path-js": () => import("./../../../src/pages/the-product-market-fit-show/episodes/{StrapiPodcastEpisodes.urlPath}.js" /* webpackChunkName: "component---src-pages-the-product-market-fit-show-episodes-strapi-podcast-episodes-url-path-js" */),
  "component---src-pages-the-product-market-fit-show-index-js": () => import("./../../../src/pages/the-product-market-fit-show/index.js" /* webpackChunkName: "component---src-pages-the-product-market-fit-show-index-js" */)
}

